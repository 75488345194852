// extracted by mini-css-extract-plugin
export var paragraph = "contact-module--paragraph--3f52Z";
export var contactForm = "contact-module--contactForm--27vcT";
export var formElement = "contact-module--formElement--3f2cB";
export var inLineInputs = "contact-module--inLineInputs--nS8y5";
export var halfFormElement = "contact-module--halfFormElement--2RTWJ";
export var styledInput = "contact-module--styledInput--35b_3";
export var errorInput = "contact-module--errorInput--1NwU2";
export var label = "contact-module--label--1Dubv";
export var buttonsContainer = "contact-module--buttonsContainer--3oT4u";
export var button = "contact-module--button--Zh48j";
export var iconsContainer = "contact-module--iconsContainer--2PeVH";