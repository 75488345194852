import React, { useContext } from "react";
import PageLayout from "../components/PageLayout";
import * as styles from './contact.module.css'
import { useForm } from "react-hook-form";
import classNames from 'classnames'
import UpworkLogoBlack from './../images/svg/upworklogoblack.inline.svg'
import UpworkLogoWhite from './../images/svg/upworklogowhite.inline.svg'
import InstagramLogoBlack from './../images/svg/instagramlogoblack.inline.svg'
import InstagramLogoWhite from './../images/svg/instagramlogowhite.inline.svg'
import LinkedInLogoBlack from './../images/svg/linkedinlogoblack.inline.svg'
import LinkedInLogoWhite from './../images/svg/linkedinlogowhite.inline.svg'
import { MY_LINKS } from "../context/consts";
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { INDUSTRIAL_LAYOUT } from "../context/consts";
import Seo from "../components/Seo";


function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
}


const ContactPage = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const { theme } = useContext(GlobalStateContext)

    const onSubmit = (data) => {
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': 'contact',
                ...data,
            })
        })
            .then(response => {
                reset();
                alert("Thanks for reaching out, I'll get back to you as soon as possible!")
            })
            .catch(error => {
                alert('Something went wrong when sending your message.');
            })
    }

    const InstagramLogo = () => {
        return (
            <React.Fragment>
                {theme === INDUSTRIAL_LAYOUT ? <InstagramLogoBlack style={{ height: "2rem" }} /> : <InstagramLogoWhite style={{ height: "2rem" }} />}
            </React.Fragment>
        )
    }

    const UpworkLogo = () => {
        return (
            <React.Fragment>
                {theme === INDUSTRIAL_LAYOUT ? <UpworkLogoBlack style={{ height: "2rem" }} /> : <UpworkLogoWhite style={{ height: "2rem" }} />}
            </React.Fragment>
        )
    }

    const LinkedinLogo = () => {
        return (
            <React.Fragment>
                {theme === INDUSTRIAL_LAYOUT ? <LinkedInLogoBlack style={{ height: "2rem" }} /> : <LinkedInLogoWhite style={{ height: "2rem" }} />}
            </React.Fragment>
        )
    }

    return (
        <PageLayout firstHead="contact" secondHead="Gab">
            <p className={styles.paragraph}>For any inquiries or questions feel free to contact me directly at <b>gabriele.faoro@gmail.com</b> or use the contact form here.</p>
            <div className={styles.iconsContainer}>

                <a href={MY_LINKS.INSTAGRAM} target="_blank" rel="noreferrer">{InstagramLogo()}&nbsp;</a>&nbsp;&nbsp;
                <a href={MY_LINKS.UPWORK} target="_blank" rel="noreferrer">{UpworkLogo()}&nbsp;</a>&nbsp;&nbsp;
                <a href={MY_LINKS.LINKEDIN} target="_blank" rel="noreferrer">{LinkedinLogo()}&nbsp;</a>&nbsp;&nbsp;
            </div>
            <form className={styles.contactForm} method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" name="bot-field" id="bot-field" />
                <input type="hidden" name="form-name" id="form-name" value="contact" />
                <p className={classNames('hidden')}>
                    <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                </p>
                <div className={styles.inLineInputs}>
                    <div className={styles.halfFormElement}>
                        <label className={styles.label} htmlFor="name">Name</label>
                        <input className={classNames(styles.styledInput, errors.name?.type === 'required' ? styles.errorInput : '')}
                            type="text" id="name" {...register("name", { required: true })} />
                    </div>
                    <div className={styles.halfFormElement}>
                        <label className={styles.label} htmlFor="email">Email</label>
                        <input className={classNames(styles.styledInput, errors.email?.type === 'required' ? styles.errorInput : '')}
                            type="email" id="email" {...register("email", { required: true })} />
                    </div>

                </div>

                <div className={styles.formElement}>
                    <label className={styles.label} htmlFor="subject">Subject</label>
                    <input className={classNames(styles.styledInput, errors.subject?.type === 'required' ? styles.errorInput : '')}
                        type="text" id="subject" {...register("subject", { required: true })} />
                </div>

                <div className={styles.formElement}>
                    <label className={styles.label} htmlFor="message">Message</label>
                    <textarea className={classNames(styles.styledInput, errors.message?.type === 'required' ? styles.errorInput : '')}
                        id="message" {...register("message", { required: true })} rows="5" />
                </div>

                <div className={styles.buttonsContainer}>
                    <input className={styles.button} type="submit" value="Send" />
                </div>
            </form>
            <Seo subTitle="Contact" />
        </PageLayout>
    )
}

export default ContactPage;